import { useSession } from "@swissvid/auth-helper";
import PaymentModal from "features/datatrans/PaymentModal";
import { Markdown } from "@swissvid/design-components";

export default function CategoryInfo({
  category,
  setShowDescription,
  className,
}) {
  const { data : { user = null } } = useSession();
  const disabled = category?.id === "23" && (localStorage.getItem('91699963e3e44e08a24462168fb29f6dPercentage') || 0) < 0.9;

  return (
    <div
      className={`p-5 ${className} flex flex-col gap-5 relative`}
    >
      <h2 className="text-2xl font-bold">
        {category.title}
      </h2>
      <Markdown>{category.description}</Markdown>
      <div className="flex gap-5 pt-5 items-center mt-auto">
        {category.subcategories?.length > 1 && <div className="flex gap-2">
          <div className="font-bold">Anzahl Lektionen</div>
          <div>{category.subcategories?.length || 1}</div>
        </div>}

        {category.price > 0 &&<div className="flex gap-2">
          <div className="font-bold">Preis</div>
          {category.price} CHF
        </div>}

        {category?.id === "23" && <div className="flex gap-2">
          <div className="font-bold">Anforderung</div>
          <div>Lektion 2 muss beendet sein</div>
        </div>}

        <div className="ml-auto">
          {!category.hasAccess ?
            <PaymentModal
              amount={Math.floor(category.price * 100)}
              currency="CHF"
              user_id={user.id}
              category_id={category.id}
            />
            // <Link
            //   to=""
            //   className="rounded-md bg-slate-200 bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            //   onClick={() => {
            //     setPaymentPage(true);
            //   }}>
            //   Kurs kaufen
            // </Link>
            :
            <button
              className={`rounded-md bg-green-200 bg-opacity-20 px-4 py-2 text-sm font-medium text-white ${disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"}`}
              disabled={disabled}
              onClick={() => {
                setShowDescription(false);
              }}

            >
              Kurs starten
            </button>
          }
        </div>
      </div>
    </div>
  );
}