import React from 'react';
import { MD5 } from 'crypto-js';


export default function CategoryCard({
  category,
  onClick = null,
  active = true,
  isHigh = false,
}) {
  const cardStyle = {
    backgroundImage: `url(${category.backgroundImage})`,
  };

  let progress = 0;
  if (category.video) {
    progress = localStorage.getItem(MD5(category.video).toString() + "Percentage") || 0
  } else if(category.subcategories?.length > 0) {
    let sum = 0;
    category.subcategories.forEach(subcategory => {
      sum += parseFloat(localStorage.getItem(MD5(subcategory.video).toString() + "Percentage") || 0);
    });
    progress = sum / category.subcategories.length;
  }
  const borderClass = {
    iffb: 'relative p-4 mb-[40%] cursor-pointer drop-shadow bg-slate-500 rounded w-full h-full m-3 bg-cover bg-center flex items-end justify-center',
  }[process.env.REACT_APP_TAILWIND_CONFIG] || 'relative overflow-hidden p-4 mb-[10%] cursor-pointer drop-shadow bg-slate-500 rounded w-full h-full m-3 bg-cover bg-center flex items-end justify-center';
  const borderClassActive = active ? "" : "opacity-50";
  const borderClassBorder = {
    'Gitarre': 'border-4 border-red-600',
    'Piano': 'border-4 border-[#2EE91E]',
    'Saxophone': 'border-4 border-[#0283FB]',
    'Gesang': 'border-4 border-[#FFC700]',
  }[category.title] || "";
  

  return (
    <div className="flex flex-col items-center">
      <div
        className={`${borderClass}  ${borderClassActive}  ${category.hasAccess ? '' : 'filter grayscale'} ${borderClassBorder} `}
        style={cardStyle} onClick={() => onClick && onClick()}
      >
        {process.env.REACT_APP_TAILWIND_CONFIG === "iffb" || <div className="bg-gradient-to-b from-transparent to-[#000] absolute top-0 left-0 w-full h-full">

        </div>}

        {category.parentCategory?.title === "Alle Kurse" && <>
          {category.price > 0 && !category.hasAccess && <div className="absolute top-2 left-2 p-2 bg-red-600 text-white rounded-full text-xs">
            {category.price}.-
          </div>}
          {category.price > 0 && category.hasAccess && <div className="absolute top-2 left-2 p-2 bg-green-600 text-white rounded-full text-xs">
            gekauft
          </div>}
          {Math.abs(category.price) < 0.01 && category.hasAccess && <div className="absolute top-2 left-2 p-2 bg-gray-600 text-white rounded-full text-xs">
            gratis
          </div>}
        </>}

          {process.env.REACT_APP_TAILWIND_CONFIG === "iffb" || <h3 className="text-center text-white text-sm font-bold drop-shadow-lg">{category.title}</h3>}

          {process.env.REACT_APP_TAILWIND_CONFIG === "iffb" && <h3 className="absolute top-full text-center text-white text-sm font-bold drop-shadow-lg h-[60px] flex justify-center items-center">
            <span>{category.title}</span>
          </h3>}
        <div
          className="absolute bottom-0 left-0 min-h-[5px] bg-red-600"
          style={{width: `${progress*100}%`}}
        >
        </div>
      </div>
    </div>
  );
}