import { Dialog, Transition } from '@headlessui/react'
import LoginForm from 'features/auth/LoginForm'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import { Tab } from '@headlessui/react'
import RegisterForm from 'features/auth/RegisterForm';
import {useSession} from '@swissvid/auth-helper';

export default function MyModal() {
  let navigate = useNavigate();
  const { showSignIn, signInOpen } = useSession();

  return (
    <>
      <Transition appear show={signInOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => showSignIn(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-top justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden p-6 text-left transition-all flex flex-col gap-5">
                  <Tab.Group>
                    <Tab.List className="flex gap-5 justify-center mt-16">
                      <Tab
                        className={({ selected }) =>
                          selected ? 'text-white' : 'text-gray-500'
                        }
                      >
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-center"
                        >
                          <FormattedMessage id="mymodal.login" />
                        </Dialog.Title>
                      </Tab>
                      {/* <Tab
                        className={({ selected }) =>
                          selected ? 'text-white' : 'text-gray-500'
                        }
                      >
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-center"
                        >
                          <FormattedMessage id="mymodal.register" />
                        </Dialog.Title>
                      </Tab> */}
                    </Tab.List>
                    <Tab.Panels>
                      <Tab.Panel>
                        <LoginForm onSuccess={() => {
                          showSignIn(false);
                          navigate('/videos');
                        }} />
                      </Tab.Panel>
                      <Tab.Panel>
                        <RegisterForm onSuccess={() => {
                          showSignIn(false);
                        }} />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
