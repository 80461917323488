import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {TranslationProvider} from "./features/i18n/TranslationProvider";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import CategoryPage from 'features/category/CategoryPage';
import ProfilePage from 'features/profile/ProfilePage';
import {
  Container,
  ContentWrapper,
  Navbar,
  NavbarItems,
  Footer,
  generateTimeStringSafely,
  useNavbarItems,
  Loading,
} from '@swissvid/design-components';
import { RequireAuth, useSession } from '@swissvid/auth-helper';
import Page from 'features/page/Page';
import MyModal from 'components/modal/MyModal';
import { useEffect } from 'react';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_BACKEND_URL,
  cache: new InMemoryCache(),
});

export default function App() {
  const { data: { user = null }, showSignIn, signOut } = useSession();
  const {
    loading: loadingNavbarItems,
    error: errorNavbarItems,
    value: navbarItems,
  } = useNavbarItems();
  useEffect(() => {
    if (errorNavbarItems) {
      console.error(errorNavbarItems);
    }
  }, [errorNavbarItems]);
  const navbarProps = {
    user,
    functions: {
      showSignIn,
      signOut,
    },
  };

  return (
    <TranslationProvider>
      <ApolloProvider client={client}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Container>
              {process.env.REACT_APP_LOGIN_PROVIDER === 'supabase' && <MyModal />}

              <Navbar
                left={<>
                  {loadingNavbarItems && <Loading />}
                  {!loadingNavbarItems && <NavbarItems items={navbarItems?.left || []} {...navbarProps} />}
                </>}
                right={<>
                  {!loadingNavbarItems && <NavbarItems items={navbarItems?.right || []} {...navbarProps} />}
                </>}
              />

              <ContentWrapper>
                <Routes>
                  <Route path="/profile" element={<RequireAuth><ProfilePage /></RequireAuth>} />
                  <Route path="/videos" element={<RequireAuth><CategoryPage /></RequireAuth>} />
                  <Route path="/videos/:categorySlug" element={<RequireAuth><CategoryPage /></RequireAuth>} />
                  <Route path="/*" element={<Page />} />
                </Routes>
              </ContentWrapper>

              <Footer
                left={
                  <>
                    <span>
                      {[
                        ['©', new Date().getFullYear(), process.env.REACT_APP_COPYRIGHT_NAME || 'swissvid'].join(' '),
                      ].filter(Boolean).join(' | ')}
                    </span>
                    &nbsp;|&nbsp;
                    <>
                      <a href="/datenschutz" title="Datenschutzerklärung ">Datenschutzerklärung</a>
                    </>
                  </>
                }
                center={
                  process.env.REACT_APP_GIT_BRANCH !== 'main' && [
                    process.env.REACT_APP_GIT_BRANCH,
                    process.env.REACT_APP_GIT_COMMIT_HASH,
                    generateTimeStringSafely(process.env.REACT_APP_GIT_COMMIT_DATE),
                    'Theme: ' + process.env.REACT_APP_TAILWIND_CONFIG,
                  ].filter(Boolean).join(' | ')
                }
                right={
                  [
                    user?.email,
                  ].filter(Boolean).join(' | ')
                }
              />
            </Container>
          </BrowserRouter>
      </ApolloProvider>
    </TranslationProvider>
  );
}